import React, { useState } from 'react';
import clsx from 'clsx';

import { PROTOCOL_ROUTE } from '../../../configs/routes';
import { useTranslation } from 'react-i18next';

import ProtocolStepper from '../ProtocolStepper';
import StdIcon from '../../atoms/StdIcon';

import './protocol-card.scss';
import { useHistory } from 'react-router';
import ProtocolCheckoutWarningModal from '../ProtocolCheckoutWarningModal';
import { useDispatch, useSelector } from 'react-redux';
import {
    getOfflineProtocolCheckoutId,
    setCheckoutLoading,
    showSnackbar
} from '../../../store/action-creators';
import { dispatchAsync } from '../../../store/storeModule';
import { backDateToFrontWithTime } from '../../../utils/formatter';
import { parseUserAgent } from '../../../utils/uaParser';
// import { goToProtocolTab } from '../../../modules/serviceWorker';
import ProtocolStateVersionWarningModal from '../ProtocolStateVersionWarningModal';
import { useCallback } from 'react';

interface IProps extends IClass {
    protocol: IProtocol;
}

const ProtocolCard: React.FC<IProps> = ({ className, protocol }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const useBeta = useSelector((state) => state.user.user.response?.use_beta);
    const offlineProtocol = useSelector((state) => state.offlineProtocol);
    const loggedInUserId = useSelector((state) => state.user.user.response?.id);
    const offlineProtocolSessionId = useSelector(
        (state) => state.global.offlineProtocolSessionId
    );

    const [openCheckoutWarningModal, setOpenCheckoutWarningModal] =
        useState(false);
    const [openVersionWarningModal, setOpenVersionWarningModal] =
        useState(false);
    const {
        prt_id,
        prt_dpd_id,
        prt_dpd_data,
        prt_status,
        prt_stage,
        prt_current_step,
        checkout_data = {},
        last_checkin = {}
    } = protocol;

    const protocolIsNotCheckedInProperly = !Boolean(checkout_data === null);
    const protocolCheckData = { ...last_checkin, ...checkout_data } as
        | IProtocolCheckoutData
        | IProtocolLastCheckinData;
    const updatedDate = backDateToFrontWithTime(protocolCheckData.created_at);

    const parsedUserData =
        ((useBeta &&
            protocolCheckData?.device_data &&
            parseUserAgent(protocolCheckData?.device_data || '')) as any) || {};

    const dpd = prt_dpd_id || prt_dpd_data;
    const { dpd_street, dpd_street_number, dpd_title } = dpd || {};
    const isFinished = prt_status === 'done';

    const protocolIsOpenWithDifferentUser = !Boolean(
        protocolCheckData.user_id === loggedInUserId
    );

    const localProtocolId = offlineProtocol.protocol.pk || 0;
    const localProtocolSessionId =
        offlineProtocol.protocol?.checkout_data?.session_id || '';
    const currentProtocolId = prt_id;
    const currentSessionId = offlineProtocolSessionId;
    const userIsNotEditing = !Boolean(
        offlineProtocol.protocolState.is_user_editing
    );

    const handleProtocolAccess = useCallback(
        (event: any) => {
            // event.persist();
            // console.log('this is the click event captured --> ', {
            //     event
            // });
            // window.dispatchEvent({ ...event, myKey: 'helloWorld' });
            // goToProtocolTab({ ...event, myKey: 'helloWorld' }, 333);

            if (useBeta) {
                // if (local PRT_ID is present)
                // // if (current SESS_ID === local SESS_ID)
                // // // load local PRT
                // // else
                // // // show modal to user
                // else
                // // do whatever was happening before

                if (localProtocolId && localProtocolId === currentProtocolId) {
                    if (
                        localProtocolSessionId === currentSessionId &&
                        userIsNotEditing &&
                        !protocolIsOpenWithDifferentUser
                    ) {
                        // also check is_user_editing should be false
                        // load Local Protocol
                        dispatchAsync(
                            dispatch,
                            getOfflineProtocolCheckoutId(
                                prt_id,
                                {
                                    override: true,
                                    session_id: offlineProtocolSessionId,
                                    check_validity: true
                                },
                                true
                            )
                        )
                            .then(() => {
                                dispatch(setCheckoutLoading(false));
                                history.push(PROTOCOL_ROUTE(prt_id));
                            })
                            .catch((e) => {
                                const errorResponseDataCode =
                                    e.error.response.data.code;
                                if (JSON.parse(errorResponseDataCode) === 101) {
                                    dispatchAsync(
                                        dispatch,
                                        showSnackbar({
                                            snackType: 'warn',
                                            message: 'offline_proto_closed'
                                        })
                                    ).then(() => {
                                        window.location.reload();
                                    });
                                }
                                dispatch(setCheckoutLoading(false));
                            });
                        console.log('loading Local Protocol');
                        // setOpenVersionWarningModal(true); // testing
                    } else {
                        // show version error modal to user
                        setOpenVersionWarningModal(true);
                    }
                } else {
                    if (
                        protocolCheckData &&
                        protocolIsOpenWithDifferentUser &&
                        protocolIsNotCheckedInProperly
                    ) {
                        setOpenCheckoutWarningModal(true);
                    } else {
                        dispatchAsync(
                            dispatch,
                            getOfflineProtocolCheckoutId(prt_id, {
                                override: true,
                                session_id: offlineProtocolSessionId,
                                check_validity: true
                            })
                        )
                            .then(() => {
                                dispatch(setCheckoutLoading(false));
                                history.push(PROTOCOL_ROUTE(prt_id));
                            })
                            .catch((e) => {
                                const errorResponseDataCode =
                                    e.error.response.data.code;
                                if (JSON.parse(errorResponseDataCode) === 101) {
                                    dispatchAsync(
                                        dispatch,
                                        showSnackbar({
                                            snackType: 'warn',
                                            message: 'offline_proto_closed'
                                        })
                                    ).then(() => {
                                        window.location.reload();
                                    });
                                }
                                dispatch(setCheckoutLoading(false));
                            });
                    }
                }
            } else {
                // redirect to protocol
                history.push(PROTOCOL_ROUTE(prt_id));
            }
        },
        [offlineProtocol]
    );

    return (
        <>
            <div
                className={clsx(
                    'protocol-card',
                    'std-blank-link',
                    useBeta ? 'protocol-card-beta-height' : '',
                    className
                )}
                onClick={handleProtocolAccess}
            >
                <div className="protocol-card__title stack-s">
                    {dpd_title || '---'}
                </div>

                <div
                    className={clsx(
                        'protocol-card__sub-title',
                        useBeta ? 'stack-xs' : 'stack-m'
                    )}
                >
                    {dpd_street || '----'} {dpd_street_number || '--'}
                </div>

                {useBeta && (
                    <div className="protocol-card__details">
                        {Boolean(checkout_data || last_checkin) && (
                            <>
                                <p className="protocol-card__details__title">
                                    {parsedUserData?.device && (
                                        <>
                                            {parsedUserData?.device?.type ===
                                            'mobile' ? (
                                                <StdIcon
                                                    className="inline-s"
                                                    name={'mobile' as any}
                                                    prefix="far"
                                                />
                                            ) : (
                                                <StdIcon
                                                    className="inline-s"
                                                    name={'desktop' as any}
                                                    prefix="fas"
                                                />
                                            )}
                                            <span className="protocol-card__details__device-name">
                                                {parsedUserData?.device?.name ||
                                                    '---'}{' '}
                                                {parsedUserData?.browser &&
                                                    `(${
                                                        parsedUserData?.browser
                                                            ?.name || '---'
                                                    })`}
                                            </span>
                                        </>
                                    )}
                                    {/* needed later */}
                                    {/* <span className="offline-status">offline</span> */}
                                </p>
                                <p className="protocol-card__details__subTitle">
                                    Last update:{' '}
                                    {protocolCheckData?.created_at
                                        ? updatedDate
                                        : '---'}
                                </p>
                                <p className="protocol-card__details__subTitle protocol-card__details__subTitle__ellipseText">
                                    By user:{' '}
                                    {protocolCheckData?.user_email || ''}
                                </p>
                            </>
                        )}
                    </div>
                )}
                {isFinished ? (
                    <div>
                        <StdIcon
                            className="inline-s"
                            name="signature"
                            prefix="far"
                        />
                        <span>{t('completed_and_signed')}</span>
                    </div>
                ) : (
                    <ProtocolStepper
                        stages={prt_stage || {}}
                        currentStep={prt_current_step || 'locate'}
                        isFinished={isFinished}
                        simplified
                    />
                )}
            </div>
            <ProtocolCheckoutWarningModal
                data={checkout_data as IProtocolCheckoutData}
                open={openCheckoutWarningModal}
                onAccept={() => {
                    // run checkout API with {override: true}
                    dispatchAsync(
                        dispatch,
                        getOfflineProtocolCheckoutId(prt_id, {
                            override: true,
                            session_id: offlineProtocolSessionId,
                            check_validity: true
                        })
                    )
                        .then(() => {
                            dispatch(setCheckoutLoading(false));
                            history.push(PROTOCOL_ROUTE(prt_id));
                        })
                        .catch((e) => {
                            const errorResponseDataCode =
                                e.error.response.data.code;
                            if (JSON.parse(errorResponseDataCode) === 101) {
                                dispatchAsync(
                                    dispatch,
                                    showSnackbar({
                                        snackType: 'warn',
                                        message: 'offline_proto_closed'
                                    })
                                ).then(() => {
                                    window.location.reload();
                                });
                            }
                            dispatch(setCheckoutLoading(false));
                        });
                }}
                onCancel={() => {
                    // close the modal
                    setOpenCheckoutWarningModal(false);
                }}
            />
            <ProtocolStateVersionWarningModal
                data={checkout_data as IProtocolCheckoutData}
                open={openVersionWarningModal}
                onFetchServer={() => {
                    dispatchAsync(
                        dispatch,
                        getOfflineProtocolCheckoutId(prt_id, {
                            override: true,
                            session_id: offlineProtocolSessionId,
                            check_validity: true
                        })
                    )
                        .then(() => {
                            dispatch(setCheckoutLoading(false));
                            history.push(PROTOCOL_ROUTE(prt_id));
                        })
                        .catch((e) => {
                            const errorResponseDataCode =
                                e.error.response.data.code;
                            if (JSON.parse(errorResponseDataCode) === 101) {
                                dispatchAsync(
                                    dispatch,
                                    showSnackbar({
                                        snackType: 'warn',
                                        message: 'offline_proto_closed'
                                    })
                                ).then(() => {
                                    window.location.reload();
                                });
                            }
                            dispatch(setCheckoutLoading(false));
                        });
                    console.log('FETCHING SERVER PROTOCOL');
                }}
                onFetchLocal={() => {
                    // will need to fix the local fetching on the offlineProtocol
                    dispatchAsync(
                        dispatch,
                        getOfflineProtocolCheckoutId(
                            prt_id,
                            {
                                override: true,
                                session_id: offlineProtocolSessionId,
                                check_validity: true
                            },
                            true
                        )
                    )
                        .then(() => {
                            dispatch(setCheckoutLoading(false));
                            history.push(PROTOCOL_ROUTE(prt_id));
                        })
                        .catch((e) => {
                            const errorResponseDataCode =
                                e.error.response.data.code;
                            if (JSON.parse(errorResponseDataCode) === 101) {
                                dispatchAsync(
                                    dispatch,
                                    showSnackbar({
                                        snackType: 'warn',
                                        message: 'offline_proto_closed'
                                    })
                                ).then(() => {
                                    window.location.reload();
                                });
                            }
                            dispatch(setCheckoutLoading(false));
                        });
                    console.log('FETCHING LOCAL PROTOCOL');
                }}
                onCancel={() => {
                    setOpenVersionWarningModal(false);
                }}
            />
        </>
    );
};

export default ProtocolCard;
